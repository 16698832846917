import { FormFieldset } from 'components/FormButton'
import { RegisterOptions, UseFormReturn } from 'react-hook-form'
import { Fieldset } from 'components/fieldset/Fieldset'
import InputMask from 'react-input-mask'
import { css } from '@emotion/react'
import { colors } from 'utils/colors'

export function DateInput(props: {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  form: UseFormReturn<any>
  birthday: string
  label?: string
  required?: boolean
  readOnly?: boolean
  validationRules?: RegisterOptions
}) {
  const { form, birthday } = props
  return (
    <FormFieldset form={form}>
      <Fieldset>
        <InputMask
          {...form.register('birthday', {
            ...(props.required ? { required: 'Required field' } : {}),
            ...props.validationRules,
          })}
          mask="99/99/9999"
          maskPlaceholder="MM/DD/YYYY"
          placeholder="&nbsp;"
          css={
            birthday === 'MM/DD/YYYY' || birthday === ''
              ? css`
                  /* type="date" shows "dd.mm.yyyy" mask which should not point out with black font so much */
                  color: ${colors.gray[500]} !important;
                  -webkit-text-fill-color: ${colors.gray[500]} !important;
                `
              : null
          }
        />
        <label>{props.label}</label>
      </Fieldset>
    </FormFieldset>
  )
}
