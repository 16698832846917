import styled from '@emotion/styled'
import { Caption, Text, Title, Title_h2_default } from 'components/Typography'
import { BREAKPOINTS, pxToRem } from 'utils/styled'
import React, { ElementType, forwardRef } from 'react'
import { css } from '@emotion/react'

export const WizardHeading = forwardRef<
  HTMLHeadingElement,
  React.HTMLProps<HTMLHeadingElement> & {
    children?: React.ReactNode
    as?: ElementType
  }
>(({ children, as, ...inputProps }, ref) => {
  return (
    <Title
      variant={Title_h2_default}
      as={as || 'h2'}
      {...inputProps}
      ref={ref}
      css={css`
        margin-bottom: 3.125rem;

        @media ${BREAKPOINTS.MD.max} {
          margin-bottom: 2rem;
        }
      `}
    >
      {children}
    </Title>
  )
})
WizardHeading.displayName = 'WizardHeading'

export const WizardSubtitle = styled(Caption)`
  margin-bottom: 1.375rem;

  @media ${BREAKPOINTS.MD.max} {
    margin-bottom: 1rem;
  }
`

export const WizardSubheading = styled(Text)`
  font-size: ${pxToRem(21)}rem;
  font-weight: bold;
  margin-bottom: 19px;
  color: var(--c-gray100);
`

export const WizardDescription = styled(Text)`
  max-width: ${pxToRem(450)}rem;

  @media ${BREAKPOINTS.MD.max} {
    max-width: unset;
  }
`
