import { useUserMe } from 'api/goodtrust/user'
import { Button } from 'components/button/Button'
import { Card } from 'components/card/Card'
import { Checkbox } from 'components/checkbox/Checkbox'
import { Fieldset } from 'components/fieldset/Fieldset'
import { FormFieldset } from 'components/FormButton'
import { InputGrid } from 'components/Grid'
import { handleSignupSubmit } from 'components/groupLifeSection/signup/submit'
import {
  isValidAgeForGroupLife,
  isValidCountryForGroupLife,
} from 'components/groupLifeSection/utils'
import { DateInput } from 'components/inputs/dateInput/DateInput'
import { PrimaryLink } from 'components/Link'
import { useAuthModal } from 'components/modal/authModal/AuthModal'
import { Select } from 'components/select/Select'
import { Spacer } from 'components/spacer/Spacer'
import { Stack } from 'components/Stack'
import { TermsAndPrivacyLinks } from 'components/TermsAndPrivacyLinks'
import { Caption, Text } from 'components/Typography'
import { parse } from 'date-fns'
import { i18n, useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Controller, useForm } from 'react-hook-form'
import { useAuth } from 'utils/auth/hooks/useAuth'
import {
  COUNTRY_OPTIONS_ISO3166_1_ALPHA2,
  COUNTRY_OPTIONS_ISO3166_1_ALPHA2_ES,
  USE_GENDER_OPTIONS,
} from 'utils/constants'
import { mapCountryNameToCode } from 'utils/country'
import { formatDate } from 'utils/date'
import { entriesOf, matchMap, pick } from 'utils/general'
import { useRunOnceReady } from 'utils/hooks'
import { useFieldValidation } from 'utils/i18n/useFieldValidation'

export type FormFields = {
  first_name: string
  last_name: string
  email: string
  sex: 'Male' | 'Female'
  birthday: string
  country: string
  postal_code: string
  hospitalized: boolean
  password: string
}

export type GroupLifeSignupVariant = 'confirm' | 'signup'

export function GroupLifeSignupForm() {
  const validation = useFieldValidation()
  const { isLogged } = useAuth()
  const userMeSwr = useUserMe()
  const userMe = userMeSwr.data?.json
  const router = useRouter()

  const form = useForm<FormFields>({
    defaultValues: {
      hospitalized: false,
      country: 'US',
    },
  })

  useRunOnceReady(!!userMe, () => {
    // loads default data once userMe is ready
    const data = pick(userMe ?? {}, 'first_name', 'last_name', 'email', 'postal_code', 'sex')
    for (const [key, value] of entriesOf(data)) {
      if (value) {
        form.setValue(key, value)
      }
    }

    const birthday = userMe?.birthday ? formatDate(userMe.birthday) : undefined
    if (birthday) {
      form.setValue('birthday', birthday)
    }

    const countryCode = userMe?.country ? mapCountryNameToCode(userMe.country) : undefined
    if (countryCode) form.setValue('country', countryCode)
  })

  const { t } = useTranslation('group_life')

  const birthday = form.watch('birthday')

  const variant: GroupLifeSignupVariant = isLogged ? 'confirm' : 'signup'

  const [authModal, authWithModal] = useAuthModal({
    config: {
      onlyLogin: true,
      loginDesc: 'It seems like you already have an account. Please login to continue.',
      source: 'GROUP_LIFE',
    },
  })

  const genders = USE_GENDER_OPTIONS()
  return (
    <section>
      {authModal}
      <Card css={{ padding: '2.5rem' }}>
        <form
          onSubmit={form.handleSubmit((data) => {
            const parsedBirthday = parse(birthday, 'MM/dd/yyyy', new Date())
            data.birthday = formatDate(parsedBirthday, 'api') ?? ''
            handleSignupSubmit(data, { authWithModal, variant, source: 'GROUP_LIFE' })
          })}
        >
          <InputGrid css={{ marginBottom: 0 }}>
            <Caption css={{ gridColumn: 'span 2' }}>
              {t(`group_life.signup_page.form.variants.${variant}.top_caption`)}
            </Caption>
            <FormFieldset form={form}>
              <Fieldset>
                <input
                  {...form.register('first_name', validation.required())}
                  type="text"
                  placeholder="&nbsp;"
                />
                <label>{t('group_life.signup_page.form.first_name')}</label>
              </Fieldset>
            </FormFieldset>
            <FormFieldset form={form}>
              <Fieldset>
                <input
                  {...form.register('last_name', validation.required())}
                  type="text"
                  placeholder="&nbsp;"
                />
                <label>{t('group_life.signup_page.form.last_name')}</label>
              </Fieldset>
            </FormFieldset>
            <FormFieldset form={form}>
              <Fieldset css={{ gridColumn: 'span 2' }}>
                <input
                  {...form.register('email', {
                    ...validation.required(),
                    ...validation.email(),
                  })}
                  disabled={isLogged}
                  type="email"
                  placeholder="&nbsp;"
                />
                <label>{t('group_life.signup_page.form.email')}</label>
              </Fieldset>
            </FormFieldset>
            {!isLogged && (
              <FormFieldset form={form}>
                <Fieldset css={{ gridColumn: 'span 2' }}>
                  <input
                    {...form.register('password', validation.required())}
                    type="password"
                    autoComplete="new-password"
                    placeholder="&nbsp;"
                  />
                  <label>{t('group_life.signup_page.form.password')}</label>
                </Fieldset>
              </FormFieldset>
            )}

            <Controller
              name={'sex'}
              rules={validation.required()}
              control={form.control}
              render={({ field }) => (
                <FormFieldset name={field.name} form={form} css={{ gridColumn: 1 }}>
                  <Select
                    {...field}
                    label={t('group_life.signup_page.form.gender')}
                    readOnly={false}
                    items={genders}
                    enableFiltering
                  />
                </FormFieldset>
              )}
            />

            <DateInput
              form={form}
              required
              birthday={birthday}
              label={t('group_life.signup_page.form.date_of_birth')}
              validationRules={{
                validate: (birthday) => {
                  const parsedBirthday = parse(birthday, 'MM/dd/yyyy', new Date())
                  if (parsedBirthday.toString() === 'Invalid Date') {
                    return t('group_life.signup_page.form.invalid_date_error')
                  }
                  return isValidAgeForGroupLife(parsedBirthday)
                    ? undefined
                    : t('group_life.signup_page.form.invalid_age_error')
                },
              }}
            />

            <Controller
              name={'country'}
              rules={{
                ...validation.required(),
                validate: (country) =>
                  isValidCountryForGroupLife(country)
                    ? undefined
                    : t('group_life.signup_page.form.invalid_country_error'),
              }}
              control={form.control}
              render={({ field }) => (
                <FormFieldset name={field.name} form={form}>
                  <Select
                    {...field}
                    label={t('group_life.signup_page.form.country') as string}
                    readOnly={false}
                    items={
                      i18n?.language === 'es'
                        ? COUNTRY_OPTIONS_ISO3166_1_ALPHA2_ES
                        : COUNTRY_OPTIONS_ISO3166_1_ALPHA2
                    }
                    enableFiltering
                  />
                </FormFieldset>
              )}
            />

            <FormFieldset form={form}>
              <Fieldset>
                <input
                  {...form.register('postal_code', {
                    ...validation.required(),
                    ...validation.invalidZipIfLessThanLength(3),
                    ...validation.usZipRegex(),
                  })}
                  type="text"
                  placeholder="&nbsp;"
                />
                <label>{t('group_life.signup_page.form.zip')}</label>
              </Fieldset>
            </FormFieldset>
          </InputGrid>

          <Spacer size="1rem" />

          <Stack space={'1.5rem'}>
            <FormFieldset form={form}>
              <Checkbox {...form.register('hospitalized', validation.required())} size={24}>
                <Text>{t('group_life.signup_page.form.hospitalized')}</Text>
              </Checkbox>
            </FormFieldset>

            <Button type="submit" variant="primary" size="large" block>
              {t('group_life.signup_page.form.submit')}
            </Button>

            {matchMap(variant, {
              confirm: null,
              signup: (
                <Text css={{ display: 'block', textAlign: 'center' }}>
                  {t('group_life.signup_page.form.existing_account.text')}{' '}
                  <Link href={'/login?next=' + router.pathname} passHref legacyBehavior>
                    <PrimaryLink>
                      {t('group_life.signup_page.form.existing_account.sign_in_link')}
                    </PrimaryLink>
                  </Link>
                </Text>
              ),
            })}
          </Stack>
        </form>
      </Card>
      <Spacer size={'1.5rem'} />
      <div css={{ textAlign: 'center' }}>
        <Text>
          By continuing you agree to our <TermsAndPrivacyLinks />
        </Text>
      </div>
    </section>
  )
}
