import { handleAuthResponse } from 'api/goodtrust/auth.util'
import { patchUserMe, postUserCheck, postUserRegister } from 'api/goodtrust/user'
import { FormFields, GroupLifeSignupVariant } from 'components/groupLifeSection/signup/Form'
import { AuthWithModalFn } from 'components/modal/authModal/AuthModal'
import { isGroupLifeAvailable } from 'logic/subscription/plan/groupLife/isGroupLifeAvailable'
import { handleAndToastError } from 'components/Toast'
import Router from 'next/router'
import { events } from 'utils/analytics'
import { updatePlanCart } from 'utils/cart'
import { encodeQuery, unwrapResponse } from 'utils/fetcher'
import { noop, pick } from 'utils/general'
import { ApiType, Awaited } from 'utils/types'

type GroupLifeSignupContext = {
  authWithModal: AuthWithModalFn
  variant: GroupLifeSignupVariant
  source: ApiType['UserCreateRequest']['source']
}

export async function handleSignupSubmit(data: FormFields, ctx: GroupLifeSignupContext) {
  try {
    const createAccountResult =
      ctx.variant === 'signup' ? await createAccountOrLogin(data, ctx) : undefined
    await saveProvidedData(data)
    await storeFormInCart(data, createAccountResult)
    await redirectToNextStep()
  } catch (err) {
    handleAndToastError(err)
  }
}

async function createAccountOrLogin(
  data: FormFields,
  ctx: GroupLifeSignupContext
): Promise<'created_account' | 'logged_in'> {
  const userAlreadyExists = await postUserCheck({ email: data.email })
    .then(unwrapResponse.body)
    .then((res) => res.status === 'EXISTS')

  if (userAlreadyExists) {
    await new Promise<void>((resolve) => ctx.authWithModal(resolve, { defaultEmail: data?.email }))
    return 'logged_in'
  }

  const authResponse = await postUserRegister({
    ...pick(data, 'email', 'first_name', 'last_name', 'password'),
    source: ctx.source ?? 'DEFAULT',
    grouplife_user: true,
  })

  events.user_registered.fire({
    type: 'group_life_insurance',
  })

  await handleAuthResponse(authResponse, true, {
    onVerificationRequired: noop,
  })

  return 'created_account'
}

async function saveProvidedData(data: FormFields) {
  await patchUserMe(
    pick(data, 'first_name', 'last_name', 'birthday', 'country', 'postal_code', 'sex')
  )
}

async function storeFormInCart(
  data: FormFields,
  createAccountResult: Awaited<ReturnType<typeof createAccountOrLogin>> | undefined
) {
  updatePlanCart((cart) => {
    cart.groupLifeForm = pick(
      data,
      'sex',
      'birthday',
      'postal_code',
      'first_name',
      'last_name',
      'country'
    )
    cart.isSigningUp = createAccountResult === 'created_account'
  })
}

async function redirectToNextStep() {
  const defaultNextStep = isGroupLifeAvailable()
    ? '/goodtrust-life/pricing'
    : encodeQuery('/me/dashboard', { onboarding: 'life' })
  const next = Router.query.next
  const nextStep = (Array.isArray(next) ? next[0] : next) ?? defaultNextStep

  await Router.push(nextStep)
}
