import { css } from '@emotion/react'
import { FC } from 'react'

export const Card: FC<React.PropsWithChildren<{ className?: string; hoverable?: boolean }>> = (
  props
) => {
  return (
    <div
      className={props.className}
      css={css`
        background: white;
        box-shadow: var(--card-shadow);
        border-radius: 2px;
        position: relative;

        border: 2px solid transparent;

        ${props.hoverable
          ? css`
              &:hover {
                box-shadow: var(--card-shadow-hover);
              }

              &:active {
                box-shadow: var(--card-shadow-active);
              }
            `
          : null}
      `}
    >
      {props.children}
    </div>
  )
}
