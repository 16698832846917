import { determineAge } from 'utils/date'

export function isValidAgeForGroupLife(birthdate: Date) {
  const age = determineAge(birthdate)

  const years = age.years ?? 0

  return years >= 18 && years <= 65
}

export function isValidCountryForGroupLife(country: string) {
  return country === 'US'
}
