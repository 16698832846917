import { createContext, FormHTMLAttributes, forwardRef, useContext, useMemo, useState } from 'react'
import { FieldValues, FormState } from 'react-hook-form'
import { useWarningOnExit } from 'utils/hooks'

export const SafeForm = forwardRef<
  HTMLFormElement,
  FormHTMLAttributes<HTMLFormElement> & {
    formState: FormState<FieldValues>
  }
>(({ children, formState, ...props }, ref) => {
  const [isEnabled, setEnabled] = useState(true)
  const shouldWarn =
    isEnabled && formState.isDirty && !formState.isSubmitting && !formState.isSubmitSuccessful

  useWarningOnExit(shouldWarn, 'You have unsaved changes. Are you sure that you want to leave?')

  const context = useMemo((): SafeFormContextValue => {
    return {
      disableWarningOnExit: () => setEnabled(false),
    }
  }, [])

  return (
    <form ref={ref} {...props}>
      <SafeFormContext.Provider value={context}>{children}</SafeFormContext.Provider>
    </form>
  )
})
SafeForm.displayName = 'SafeForm'

export type SafeFormContextValue = {
  disableWarningOnExit: () => void
}
export const SafeFormContext = createContext<SafeFormContextValue | undefined>(undefined)
export const useSafeFormContext = () => useContext(SafeFormContext)
