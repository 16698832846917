import { COUNTRY_OPTIONS_ISO3166_1_ALPHA2 } from 'utils/constants'

export function mapCountryNameToCode(countryName: string): string | undefined {
  const lowercaseCountryName = countryName.toLowerCase().trim()
  if (lowercaseCountryName === '') return undefined

  const item = COUNTRY_OPTIONS_ISO3166_1_ALPHA2.find((item) => {
    return item.label.toLowerCase() === lowercaseCountryName
  })

  return item?.value
}
